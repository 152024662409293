import {clientsClaim, skipWaiting} from 'workbox-core';
import {ExpirationPlugin} from 'workbox-expiration';
import {precacheAndRoute} from 'workbox-precaching';
import {registerRoute} from 'workbox-routing';
import {CacheFirst, NetworkFirst, StaleWhileRevalidate} from 'workbox-strategies';

self.__WB_DISABLE_DEV_LOGS = true;

// Configure workbox
clientsClaim();
skipWaiting();

precacheAndRoute(self.__WB_MANIFEST);
/* precacheAndRoute([
    {url: '/assets/app/css/app.css', revision: null},
    {url: '/assets/app/js/app.js', revision: null},
]); */

const imagesToCache = [
    '/assets/app/img/bs_inspectie.png',
    '/assets/app/img/bs_opdrachtgever.png',
    '/assets/app/img/bs_profiel.png',
    '/assets/app/img/drops_big.png',
    '/assets/app/img/drops_big_7procent.png',
    '/assets/app/img/drops_big_highcontrast.png',
    '/assets/app/img/drops_new.png',
    '/assets/app/img/drops_new_big.png',
    '/assets/app/img/druppels.png',
    '/assets/app/img/Favico.png',
    '/assets/app/img/icon_pakkingen.png',
    '/assets/app/img/logo.png',
    '/assets/app/img/logo_sloganless.png',
    '/assets/app/img/svg/alert01.svg',
    '/assets/app/img/svg/alert02.svg',
    '/assets/app/img/svg/alert03.svg',
    '/assets/app/img/svg/alert04.svg',
    '/assets/app/img/svg/Logo.svg'
];

const fontsToCache = [
    '/assets/app/fonts/fontawesome-webfont.eot',
    '/assets/app/fonts/fontawesome-webfont.svg',
    '/assets/app/fonts/fontawesome-webfont.ttf',
    '/assets/app/fonts/fontawesome-webfont.woff',
    '/assets/app/fonts/fontawesome-webfont.woff2',
    '/assets/app/fonts/FontAwesome.otf',
    '/assets/app/webfonts/fa-brands-400.eot',
    '/assets/app/webfonts/fa-brands-400.svg',
    '/assets/app/webfonts/fa-brands-400.ttf',
    '/assets/app/webfonts/fa-brands-400.woff',
    '/assets/app/webfonts/fa-brands-400.woff2',
    '/assets/app/webfonts/fa-duotone-900.eot',
    '/assets/app/webfonts/fa-duotone-900.svg',
    '/assets/app/webfonts/fa-duotone-900.ttf',
    '/assets/app/webfonts/fa-duotone-900.woff',
    '/assets/app/webfonts/fa-duotone-900.woff2',
    '/assets/app/webfonts/fa-light-300.eot',
    '/assets/app/webfonts/fa-light-300.svg',
    '/assets/app/webfonts/fa-light-300.ttf',
    '/assets/app/webfonts/fa-light-300.woff',
    '/assets/app/webfonts/fa-light-300.woff2',
    '/assets/app/webfonts/fa-regular-400.eot',
    '/assets/app/webfonts/fa-regular-400.svg',
    '/assets/app/webfonts/fa-regular-400.ttf',
    '/assets/app/webfonts/fa-regular-400.woff',
    '/assets/app/webfonts/fa-regular-400.woff2',
    '/assets/app/webfonts/fa-solid-900.eot',
    '/assets/app/webfonts/fa-solid-900.svg',
    '/assets/app/webfonts/fa-solid-900.ttf',
    '/assets/app/webfonts/fa-solid-900.woff',
    '/assets/app/webfonts/fa-solid-900.woff2'
];

// Define regular expressions for file extensions
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');

const MAIN_URL = '/';

// Fallback url logics
const fallbackPlugin = {
    handlerDidError: async () => {
        return await caches.match(MAIN_URL);
    },
};

// Handle navigation requests
registerRoute(
    ({ request, url }) => {
        if (request.mode !== 'navigate') {
            return false;
        }

        if (url.pathname.startsWith('/dashboard')) {
            return false;
        }

        if (url.pathname.startsWith('/_')) {
            return false;
        }

        return !url.pathname.match(fileExtensionRegexp);


    },
    new StaleWhileRevalidate({plugins: [fallbackPlugin]}) // Or use StaleWhileRevalidate if you prefer
);

registerRoute(
    '/manifest.json',
    new StaleWhileRevalidate({
        cacheName: 'manifest-json'
    })
);

registerRoute(
    ({ url }) => url.pathname.startsWith('/assets/'),
    new CacheFirst({
        cacheName: 'assets-cache'
    })
);

// Cache images with a stale-while-revalidate strategy
registerRoute(
    ({ url }) => url.origin === self.location.origin && url.pathname.endsWith('.png') || url.pathname.endsWith('.svg'),
    new StaleWhileRevalidate({
        cacheName: 'images',
        plugins: [
            new ExpirationPlugin({ maxEntries: 50 }),
        ],
    })
);

// Handle SKIP_WAITING message to activate the new service worker immediately
self.addEventListener('message', (event) => {
    if (event.data && event.data.type === 'SKIP_WAITING') {
        self.skipWaiting();
    }
});

// Cache Google Fonts
registerRoute(
    /https:\/\/fonts.(?:googleapis|gstatic)\.com.*$/,
    new StaleWhileRevalidate({
        cacheName: 'google-fonts',
        plugins: [
            new ExpirationPlugin({
                maxEntries: 30,
            }),
        ],
    })
);

// Cache any additional CSS and JS files.
registerRoute(
    /\.(?:js|css|json)$/,
    new StaleWhileRevalidate({
        cacheName: 'static-resources',
        plugins: [
            new ExpirationPlugin({
                maxEntries: 999,
            }),
        ],
    })
);

self.addEventListener("install", (event) => {
    event.waitUntil(
        caches
            .open("BatterySprayInspectionsCache-v1")
            .then((cache) =>
                cache.addAll([
                    // Cache the main page so we can serve the html if users go offline
                    MAIN_URL,
                    '/assets/app/js/manifest.json',
                    ...fontsToCache,
                    ...imagesToCache,
                ]),
            ),
    );
});

registerRoute(
    ({request}) => request.method === 'GET',
    new NetworkFirst({
        cacheName: 'BatterySprayInspectionsCache-v1',
    })
)

self.addEventListener("fetch", (event) => {
    if (event.request.method !== "GET") {
        return;
    }

    event.respondWith(
        caches.match(event.request).then((response) => {
            // caches.match() always resolves
            // but in case of success response will have value
            if (response !== undefined) {
                return response;
            } else {
                return fetch(event.request)
                    .then((response) => {
                        let responseClone = response.clone();

                        caches.open("BatterySprayInspectionsCache-v1").then((cache) => {
                            cache.put(event.request, responseClone);
                        });
                        return response;
                    })
                    .catch(() => caches.match("/assets/app/js/manifest.json"));
            }
        }),
    );
});
